import { ArrowRight, Play, Scissors, Upload } from "lucide-react";
import { Footer } from "~/components/footer";
import { Header } from "~/components/header";
import { Button } from "~/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";

export default function LandingPage() {
	return (
		<div className="flex flex-col min-h-screen">
			<Header />
			<main className="flex-1">
				<HeroSection />
				<AboutSection />
				<FeaturesSection />
				<HowItWorksSection />
				<BenefitsSection />
				<PricingSection />
				<FAQSection />
			</main>
			<Footer />
		</div>
	);
}

function HeroSection() {
	return (
		<section className="relative w-full min-h-[80vh] flex items-center justify-center overflow-hidden bg-gradient-to-br from-purple-600 via-pink-500 to-blue-500">
			<div className="relative z-10 text-white text-center px-4 max-w-[700px] mx-auto space-y-5">
				<div className="inline-block animate-bounce mb-4">
					<span className="bg-white text-purple-600 px-3 py-1 rounded-full text-sm font-bold">
						VTuber向け
					</span>
				</div>

				<h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
					<span className="text-yellow-300">VTuber</span>の魅力を
					<span className="text-cyan-300">ショート動画</span>で最大化！
				</h1>

				<p className="mx-auto md:text-xl text-white">
					キリクリでYouTube配信やゲーム実況から自動で魅力的なショート動画を作成。
					<span className="font-bold">
						ファンを増やし、チャンネル登録者数アップ
					</span>
					につなげましょう！
				</p>

				<p className="mx-auto md:text-lg text-gray-200">
					クレジットカード登録から7日間は無料でお試しいただけます。
				</p>

				<div className="space-x-4 pt-4">
					<Button
						className="bg-gradient-to-r from-pink-500 to-purple-600 hover:from-pink-600 hover:to-purple-700 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition hover:scale-105"
						asChild
					>
						<a href="/sign-up">今すぐ始める</a>
					</Button>
					<Button
						className="bg-gradient-to-r from-pink-500 to-purple-600 hover:from-pink-600 hover:to-purple-700 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition hover:scale-105"
						asChild
					>
						<a href="#features">機能を詳しく見る</a>
					</Button>
				</div>
			</div>

			<div className="absolute inset-0 bg-black bg-opacity-30" />
		</section>
	);
}

function AboutSection() {
	return (
		<section
			id="about"
			className="w-full py-12 md:py-24 lg:py-32 bg-white dark:bg-gray-900 relative overflow-hidden"
		>
			{/* 装飾要素 */}
			<div className="absolute top-0 left-0 w-40 h-40 bg-purple-200 rounded-full -translate-x-1/2 -translate-y-1/2 opacity-50" />
			<div className="absolute bottom-0 right-0 w-60 h-60 bg-pink-200 rounded-full translate-x-1/3 translate-y-1/3 opacity-50" />

			<div className="container px-4 md:px-6 max-w-4xl mx-auto text-center space-y-8 relative z-10">
				<div className="inline-block bg-gradient-to-r from-purple-600 to-pink-500 text-white px-4 py-1 rounded-full text-sm font-bold mb-2">
					VTuberの活動をサポート
				</div>

				<h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-500">
					キリクリとは？
				</h2>

				<p className="text-gray-700 md:text-xl dark:text-gray-300 font-medium">
					VTuberの配信やゲーム実況をAIが自動でショート動画に変換するサービスです。
				</p>

				<div className="grid md:grid-cols-2 gap-8 text-left">
					<div className="bg-purple-50 dark:bg-gray-800 p-6 rounded-xl shadow-md">
						<p className="text-gray-700 md:text-lg dark:text-gray-300">
							<span className="font-bold text-purple-600 dark:text-purple-400">
								VTuberの魅力を最大限に引き出す
							</span>{" "}
							-
							長時間の配信やゲーム実況から、視聴者が最も反応する瞬間をAIが自動検出。ファンが増える魅力的なショート動画を簡単に作成できます。
						</p>
					</div>

					<div className="bg-pink-50 dark:bg-gray-800 p-6 rounded-xl shadow-md">
						<p className="text-gray-700 md:text-lg dark:text-gray-300">
							<span className="font-bold text-pink-600 dark:text-pink-400">
								個人VTuberの強い味方
							</span>{" "}
							- 編集スキルや時間がなくても、YouTube Shorts、TikTok、Instagram
							Reelsに最適化された縦型動画を自動生成。料金は10分の動画処理ごとに150円の従量課金制です。
						</p>
					</div>
				</div>

				<div className="bg-gradient-to-r from-purple-100 to-pink-100 dark:from-purple-900 dark:to-pink-900 p-6 rounded-xl shadow-md">
					<p className="text-gray-700 md:text-lg dark:text-gray-300">
						クレジットカード登録から7日間は無料でお試しいただけます。
					</p>
				</div>
			</div>
		</section>
	);
}

function FeaturesSection() {
	return (
		<section
			id="features"
			className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-b from-purple-50 to-pink-50 dark:from-gray-900 dark:to-gray-800"
		>
			<div className="container px-4 md:px-6 max-w-6xl mx-auto">
				<div className="text-center mb-12">
					<span className="inline-block bg-purple-100 text-purple-800 px-3 py-1 rounded-full text-sm font-bold mb-4">
						VTuber特化機能
					</span>
					<h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-500">
						VTuberのための主な機能
					</h2>
					<p className="mt-4 text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
						個人VTuberの活動をサポートする、キリクリの特徴的な機能をご紹介します
					</p>
				</div>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
					<Card className="border-none bg-white dark:bg-gray-800 shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2 overflow-hidden">
						<div className="absolute top-0 right-0 w-20 h-20 bg-purple-200 rounded-full -translate-x-1/2 -translate-y-1/2 opacity-50" />
						<CardHeader className="pb-0">
							<div className="w-14 h-14 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center mb-4">
								<Play className="w-8 h-8 text-purple-600 dark:text-purple-400" />
							</div>
							<CardTitle className="text-xl font-bold text-purple-700 dark:text-purple-300">
								リアクション検出
							</CardTitle>
						</CardHeader>
						<CardContent>
							<p className="text-gray-600 dark:text-gray-300">
								ゲーム実況や雑談配信から、視聴者が最も反応する面白い瞬間や感情表現をAIが自動検出し、ショート動画を生成します。
							</p>
						</CardContent>
					</Card>

					<Card className="border-none bg-white dark:bg-gray-800 shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2 overflow-hidden">
						<div className="absolute top-0 right-0 w-20 h-20 bg-pink-200 rounded-full -translate-x-1/2 -translate-y-1/2 opacity-50" />
						<CardHeader className="pb-0">
							<div className="w-14 h-14 rounded-full bg-pink-100 dark:bg-pink-900 flex items-center justify-center mb-4">
								<Scissors className="w-8 h-8 text-pink-600 dark:text-pink-400" />
							</div>
							<CardTitle className="text-xl font-bold text-pink-700 dark:text-pink-300">
								区間指定でコスト削減
							</CardTitle>
						</CardHeader>
						<CardContent>
							<p className="text-gray-600 dark:text-gray-300">
								長時間の配信アーカイブでも、ハイライトにしたい区間だけを指定可能。処理時間を短縮し、コストを大幅に抑えながら魅力的なショート動画を作成できます。
							</p>
						</CardContent>
					</Card>

					<Card className="border-none bg-white dark:bg-gray-800 shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2 overflow-hidden">
						<div className="absolute top-0 right-0 w-20 h-20 bg-blue-200 rounded-full -translate-x-1/2 -translate-y-1/2 opacity-50" />
						<CardHeader className="pb-0">
							<div className="w-14 h-14 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center mb-4">
								<Upload className="w-8 h-8 text-blue-600 dark:text-blue-400" />
							</div>
							<CardTitle className="text-xl font-bold text-blue-700 dark:text-blue-300">
								マルチプラットフォーム対応
							</CardTitle>
						</CardHeader>
						<CardContent>
							<p className="text-gray-600 dark:text-gray-300">
								YouTube Shorts、TikTok、Instagram
								Reelsなど、各プラットフォームの推奨仕様に合わせた最適化を自動で行います。
							</p>
						</CardContent>
					</Card>
				</div>
			</div>
		</section>
	);
}

function HowItWorksSection() {
	return (
		<section
			id="how-it-works"
			className="w-full py-12 md:py-24 lg:py-32 bg-white dark:bg-gray-900 relative"
		>
			{/* 装飾要素 */}
			<div className="absolute left-0 top-1/4 w-24 h-24 bg-purple-200 rounded-full opacity-50" />
			<div className="absolute right-0 bottom-1/4 w-32 h-32 bg-pink-200 rounded-full opacity-50" />

			<div className="container px-4 md:px-6 max-w-4xl mx-auto text-center space-y-10 relative z-10">
				<div>
					<span className="inline-block bg-purple-100 text-purple-800 px-3 py-1 rounded-full text-sm font-bold mb-4">
						簡単3ステップ
					</span>
					<h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-500">
						VTuberのためのご利用の流れ
					</h2>
					<p className="mt-4 text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
						配信やゲーム実況の動画から、魅力的なショート動画を簡単3ステップで作成
					</p>
				</div>

				<div className="grid md:grid-cols-3 gap-8">
					<div className="bg-purple-50 dark:bg-gray-800 p-6 rounded-xl shadow-md relative">
						<div className="absolute -top-4 -left-4 w-12 h-12 bg-purple-600 rounded-full flex items-center justify-center text-white font-bold text-xl">
							1
						</div>
						<h3 className="text-xl font-bold text-purple-700 dark:text-purple-300 mb-2">
							アップロード
						</h3>
						<p className="text-gray-600 dark:text-gray-300">
							配信やゲーム実況のYouTube URLと動画ファイルをアップロード。
						</p>
					</div>

					<div className="bg-pink-50 dark:bg-gray-800 p-6 rounded-xl shadow-md relative">
						<div className="absolute -top-4 -left-4 w-12 h-12 bg-pink-600 rounded-full flex items-center justify-center text-white font-bold text-xl">
							2
						</div>
						<h3 className="text-xl font-bold text-pink-700 dark:text-pink-300 mb-2">
							AI処理
						</h3>
						<p className="text-gray-600 dark:text-gray-300">
							AIがVTuberの魅力的なリアクションや面白いシーンを自動検出。
						</p>
					</div>

					<div className="bg-blue-50 dark:bg-gray-800 p-6 rounded-xl shadow-md relative">
						<div className="absolute -top-4 -left-4 w-12 h-12 bg-blue-600 rounded-full flex items-center justify-center text-white font-bold text-xl">
							3
						</div>
						<h3 className="text-xl font-bold text-blue-700 dark:text-blue-300 mb-2">
							完成・共有
						</h3>
						<p className="text-gray-600 dark:text-gray-300">
							縦型ショート動画が完成。各プラットフォームへの投稿ですぐにファン獲得！
						</p>
					</div>
				</div>

				<div className="mt-8">
					<Button
						className="bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition hover:scale-105"
						asChild
					>
						<a href="/sign-up">今すぐ始める</a>
					</Button>
					<p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
						クレジットカード登録から7日間は無料でお試しいただけます
					</p>
				</div>
			</div>
		</section>
	);
}

function BenefitsSection() {
	return (
		<section
			id="benefits"
			className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-b from-purple-50 to-pink-50 dark:from-gray-900 dark:to-gray-800"
		>
			<div className="container px-4 md:px-6 max-w-4xl mx-auto text-center space-y-8">
				<div>
					<span className="inline-block bg-purple-100 text-purple-800 px-3 py-1 rounded-full text-sm font-bold mb-4">
						VTuber活動を加速
					</span>
					<h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-500">
						個人VTuberのためのメリット
					</h2>
				</div>

				<div className="grid md:grid-cols-2 gap-8">
					<div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-md text-left border-l-4 border-purple-500">
						<div className="flex items-start space-x-4">
							<div className="bg-purple-100 dark:bg-purple-900 p-3 rounded-full">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6 text-purple-600 dark:text-purple-400"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<title>時間アイコン</title>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
							</div>
							<div>
								<h3 className="text-xl font-bold text-purple-700 dark:text-purple-300 mb-2">
									時間の節約
								</h3>
								<p className="text-gray-600 dark:text-gray-300">
									配信やゲーム実況に集中できる！動画編集の時間を大幅に削減し、コンテンツ制作に専念できます。
								</p>
							</div>
						</div>
					</div>

					<div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-md text-left border-l-4 border-pink-500">
						<div className="flex items-start space-x-4">
							<div className="bg-pink-100 dark:bg-pink-900 p-3 rounded-full">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6 text-pink-600 dark:text-pink-400"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<title>コストアイコン</title>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
									/>
								</svg>
							</div>
							<div>
								<h3 className="text-xl font-bold text-pink-700 dark:text-pink-300 mb-2">
									コスト削減
								</h3>
								<p className="text-gray-600 dark:text-gray-300">
									個人VTuberの強い味方！編集者に依頼する費用を抑えながら、プロ品質のショート動画を作成できます。
								</p>
							</div>
						</div>
					</div>

					<div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-md text-left border-l-4 border-blue-500">
						<div className="flex items-start space-x-4">
							<div className="bg-blue-100 dark:bg-blue-900 p-3 rounded-full">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6 text-blue-600 dark:text-blue-400"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<title>ファン獲得アイコン</title>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M13 10V3L4 14h7v7l9-11h-7z"
									/>
								</svg>
							</div>
							<div>
								<h3 className="text-xl font-bold text-blue-700 dark:text-blue-300 mb-2">
									ファン獲得の加速
								</h3>
								<p className="text-gray-600 dark:text-gray-300">
									魅力的なショート動画で新規ファンを獲得！チャンネル登録者数の増加やスーパーチャットの増加につながります。
								</p>
							</div>
						</div>
					</div>

					<div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-md text-left border-l-4 border-green-500">
						<div className="flex items-start space-x-4">
							<div className="bg-green-100 dark:bg-green-900 p-3 rounded-full">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6 text-green-600 dark:text-green-400"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<title>マルチプラットフォームアイコン</title>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
							</div>
							<div>
								<h3 className="text-xl font-bold text-green-700 dark:text-green-300 mb-2">
									マルチプラットフォーム展開
								</h3>
								<p className="text-gray-600 dark:text-gray-300">
									YouTube Shorts、TikTok、Instagram
									Reelsなど、複数のプラットフォームで同時に活動範囲を広げられます。
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-md border border-purple-200 dark:border-purple-800 mt-8">
					<div className="flex flex-col md:flex-row items-center justify-between">
						<div className="mb-4 md:mb-0 text-left">
							<h3 className="text-xl font-bold text-purple-700 dark:text-purple-300">
								VTuber活動を今すぐ加速させましょう！
							</h3>
							<p className="text-gray-600 dark:text-gray-300">
								クレジットカード登録から7日間は無料でお試しいただけます。
							</p>
						</div>
						<Button
							className="bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition hover:scale-105"
							asChild
						>
							<a href="/sign-up">無料でお試し</a>
						</Button>
					</div>
				</div>
			</div>
		</section>
	);
}

function PricingSection() {
	return (
		<section
			id="pricing"
			className="w-full py-12 md:py-24 lg:py-32 bg-white dark:bg-gray-900 relative"
		>
			{/* 装飾要素 */}
			<div className="absolute left-0 top-1/4 w-24 h-24 bg-purple-200 rounded-full opacity-50" />
			<div className="absolute right-0 bottom-1/4 w-32 h-32 bg-pink-200 rounded-full opacity-50" />

			<div className="container px-4 md:px-6 max-w-4xl mx-auto text-center space-y-10 relative z-10">
				<div>
					<span className="inline-block bg-purple-100 text-purple-800 px-3 py-1 rounded-full text-sm font-bold mb-4">
						シンプルな料金体系
					</span>
					<h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-500">
						個人VTuberに優しい料金プラン
					</h2>
					<p className="mt-4 text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
						必要な分だけお支払い。無駄な月額固定費はありません
					</p>
				</div>

				<div className="bg-gradient-to-r from-purple-50 to-pink-50 dark:from-gray-800 dark:to-gray-700 p-8 rounded-2xl shadow-xl">
					<div className="flex flex-col items-center">
						<div className="bg-white dark:bg-gray-800 rounded-full w-24 h-24 flex items-center justify-center mb-6 shadow-md">
							<span className="text-3xl font-bold text-purple-600 dark:text-purple-400">
								¥150
							</span>
						</div>

						<h3 className="text-2xl font-bold text-purple-700 dark:text-purple-300 mb-4">
							従量課金制
						</h3>

						<p className="text-xl text-gray-700 dark:text-gray-300 mb-6">
							10分の動画処理ごとに<span className="font-bold">150円</span>
						</p>

						<ul className="text-left space-y-4 mb-8">
							<li className="flex items-start">
								<svg
									className="h-6 w-6 text-green-500 mr-2 flex-shrink-0"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<title>チェックマークアイコン</title>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M5 13l4 4L19 7"
									/>
								</svg>
								<span className="text-gray-600 dark:text-gray-300">
									<span className="font-semibold">使った分だけ</span>のお支払い
									- 月額固定費なし
								</span>
							</li>
							<li className="flex items-start">
								<svg
									className="h-6 w-6 text-green-500 mr-2 flex-shrink-0"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<title>チェックマークアイコン</title>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M5 13l4 4L19 7"
									/>
								</svg>
								<span className="text-gray-600 dark:text-gray-300">
									<span className="font-semibold">区間指定</span>
									で処理時間を短縮し、コストを抑制
								</span>
							</li>
							<li className="flex items-start">
								<svg
									className="h-6 w-6 text-green-500 mr-2 flex-shrink-0"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<title>チェックマークアイコン</title>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M5 13l4 4L19 7"
									/>
								</svg>
								<span className="text-gray-600 dark:text-gray-300">
									<span className="font-semibold">今なら7日間無料</span> -
									クレジットカード登録から7日間は無料でお試し
								</span>
							</li>
						</ul>

						<div className="bg-purple-100 dark:bg-purple-900/30 p-4 rounded-lg w-full mb-6">
							<p className="text-purple-800 dark:text-purple-300 font-medium">
								例: 1時間の配信から20分間のハイライト区間を指定した場合、たった
								<span className="font-bold">300円</span>
								でショート動画が複数作成できます！
							</p>
						</div>

						<Button
							className="bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white font-bold py-3 px-8 rounded-full shadow-lg transform transition hover:scale-105 w-full md:w-auto"
							asChild
						>
							<a href="/sign-up">7日間無料でお試し</a>
						</Button>
					</div>
				</div>
			</div>
		</section>
	);
}

function FAQSection() {
	return (
		<section
			id="faq"
			className="w-full py-12 md:py-24 lg:py-32 bg-white dark:bg-gray-900"
		>
			<div className="container px-4 md:px-6 max-w-4xl mx-auto">
				<div className="text-center mb-12">
					<span className="inline-block bg-purple-100 text-purple-800 px-3 py-1 rounded-full text-sm font-bold mb-4">
						サポート情報
					</span>
					<h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-500">
						よく寄せられる質問
					</h2>
				</div>

				<div className="grid gap-6">
					<Card className="border-none bg-gradient-to-r from-purple-50 to-pink-50 dark:from-gray-800 dark:to-gray-800 shadow-md hover:shadow-lg transition-all duration-300">
						<CardHeader>
							<CardTitle className="flex items-center text-purple-700 dark:text-purple-300">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6 mr-2 text-purple-500"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<title>質問アイコン1</title>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
								ゲーム実況の動画でも使えますか？
							</CardTitle>
						</CardHeader>
						<CardContent>
							<p className="text-gray-600 dark:text-gray-300">
								はい、ゲーム実況の動画も最適化できます！AIがVTuberのリアクションや面白いゲームプレイの瞬間を自動検出し、魅力的なショート動画に変換します。
							</p>
						</CardContent>
					</Card>

					<Card className="border-none bg-gradient-to-r from-purple-50 to-pink-50 dark:from-gray-800 dark:to-gray-800 shadow-md hover:shadow-lg transition-all duration-300">
						<CardHeader>
							<CardTitle className="flex items-center text-purple-700 dark:text-purple-300">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6 mr-2 text-purple-500"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<title>質問アイコン2</title>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
								なぜYouTubeリンクと動画ファイルの両方が必要ですか？
							</CardTitle>
						</CardHeader>
						<CardContent>
							<p className="text-gray-600 dark:text-gray-300">
								動画メタデータと実ファイルを組み合わせることで、AIがVTuberの表情やリアクションをより正確に検出できます。これにより、ファンが最も反応する魅力的なシーンを的確に抽出できます。
							</p>
						</CardContent>
					</Card>

					<Card className="border-none bg-gradient-to-r from-purple-50 to-pink-50 dark:from-gray-800 dark:to-gray-800 shadow-md hover:shadow-lg transition-all duration-300">
						<CardHeader>
							<CardTitle className="flex items-center text-purple-700 dark:text-purple-300">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6 mr-2 text-purple-500"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<title>質問アイコン3</title>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
								料金について教えてください。
							</CardTitle>
						</CardHeader>
						<CardContent>
							<p className="text-gray-600 dark:text-gray-300">
								10分の動画処理ごとに150円の従量課金制です。個人VTuberの方でも負担なくご利用いただける料金設定です。クレジットカード登録から7日間は無料でお試しいただけます。
							</p>
						</CardContent>
					</Card>

					<Card className="border-none bg-gradient-to-r from-purple-50 to-pink-50 dark:from-gray-800 dark:to-gray-800 shadow-md hover:shadow-lg transition-all duration-300">
						<CardHeader>
							<CardTitle className="flex items-center text-purple-700 dark:text-purple-300">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6 mr-2 text-purple-500"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<title>質問アイコン4</title>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
								生成されたショート動画の著作権はどうなりますか？
							</CardTitle>
						</CardHeader>
						<CardContent>
							<p className="text-gray-600 dark:text-gray-300">
								生成されたショート動画の著作権は原著作者に帰属します。生成された動画は自由に各プラットフォームで公開・収益化いただけます。
							</p>
						</CardContent>
					</Card>
				</div>

				<div className="mt-12 text-center">
					<p className="text-gray-600 dark:text-gray-300 mb-6">
						他にご質問がありましたら、お気軽にお問い合わせください
					</p>
					<Button
						variant="outline"
						className="border-2 border-purple-500 text-purple-600 hover:bg-purple-500 hover:text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition hover:scale-105"
						asChild
					>
						<a
							href="https://forms.gle/cgEvjVmXpVHfeb476"
							target="_blank"
							rel="noreferrer"
							className="hover:underline"
						>
							お問い合わせ
						</a>
					</Button>
				</div>
			</div>
		</section>
	);
}
